<template>
  <div>
    <!-- Barra de navegación con transición -->

    <!-- Imagen de portada con texto -->
    <transition name="fade">
      <div
        v-if="isVisible"
        class="fotoportada-container animate__animated animate__fadeIn"
      >
      <img 
          src="@/assets/s1.jpg" 
          alt="Imagen de portada" 
          class="fotoportada-img" 
        >
        <div class="overlay">
          <!-- Imagen superpuesta encima de la imagen de fondo -->
          <img
            src="@/assets/iniciogif.gif"
            alt="Superpuesta"
            class="superposed-image"
          >
    
      
          <!-- Texto centrado sobre la imagen -->
          <p class="centered-text h1" />
        </div>
      </div>
    </transition>

    <!-- Contenedor de cuenta regresiva justo debajo de la imagen de portada -->
    <div class="countdown-container">
      <div class="svg-aro">
        <div class="svg-container">
          <img
            :src="require('@/assets/circulocontador.svg')"
            alt="SVG Icon"
            class="svg-icon"
          >
          <div
            class="textfaltan"
          >
            Faltan:
          </div>
          <img
            :src="require('@/assets/img_linea01.svg')"
            alt="SVG Icon"
            class="svg-icon2"
          >

          <transition name="fade">
            <div
              v-if="isVisible"
              class="countdown animate__animated animate__fadeInUp"
            >
              <div class="countdown-item">
                <span>{{ countdownText.split(' ')[0] }}</span> <!-- Días -->
                <label>Días</label>
              </div>
              <div class="countdown-item">
                <span>{{ countdownText.split(' ')[1] }}</span> <!-- Horas -->
                <label>Horas</label>
              </div>
              <div class="countdown-item">
                <span>{{ countdownText.split(' ')[2] }}</span> <!-- Minutos -->
                <label>Minutos</label>
              </div>
              <div class="countdown-item">
                <span>{{ countdownText.split(' ')[3] }}</span> <!-- Segundos -->
                <label>Segundos</label>
              </div>
            </div>
          </transition>
          <div
            ref="lottieContainer"
            class="lottie-animation-container"
          />
        </div>
      </div>
      <!--
<h1 class="textfaltan2">
  {{ numero }} {{ fechaevento }}
</h1>

      <div
        ref="textRef"
        class="textfaltan2"
      >
      </div>-->
    </div>
  </div>
</template>




<script>
import portadaP from '../assets/portada-1.jpg';
import lottie from 'lottie-web';


import { ref, onMounted, onBeforeUnmount } from 'vue';

export default {
  name: 'NavbarComponent',
  components: {
  },
  setup() {
    const fotoPortada = portadaP;
    const isVisible = ref(true);
    const text = ref('M | J');
    const showMenu = ref(false);
    const isNavbarHidden = ref(false);
    const scrollThreshold = ref(100);
    let lastScrollTop = 0;
    const countdownText = ref('00:00:00:00');
    const fechaevento = ref('Marzo 2025');
    const textRef = ref(null);
    const numero = ref(1);
    const lottieContainer = ref(null);


    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTop && currentScrollTop > scrollThreshold.value) {
        isNavbarHidden.value = true;
      } else if (currentScrollTop < lastScrollTop) {
        isNavbarHidden.value = false;
      }

      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    };

    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    };

    const updateCountdown = () => {
  const endDate = new Date('2025-03-17T19:00:00'); // Fecha de finalización del evento
  const now = new Date(); // Fecha actual

  // Calcular la diferencia total en milisegundos
  let difference = endDate - now;

  if (difference <= 0) {
    // Si la fecha de finalización ya ha pasado, detener el contador
    clearInterval(countdownInterval);
    countdownText.value = '00:00:00:00';
    return;
  }

  // Calcular días, horas, minutos y segundos
  const days = Math.floor(difference / (1000 * 60 * 60 * 24)); // Días
  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // Horas
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)); // Minutos
  const seconds = Math.floor((difference % (1000 * 60)) / 1000); // Segundos

  // Actualizar el texto del contador con formato adecuado
  countdownText.value = `${String(days).padStart(2, '0')} ${String(hours).padStart(2, '0')} ${String(minutes).padStart(2, '0')} ${String(seconds).padStart(2, '0')}`;
};

// Llamar la función para actualizar el contador cada segundo
let countdownInterval = setInterval(updateCountdown, 1000);

console.log(countdownText.value);



    onMounted(() => {
      // Cargar la animación Lottie desde el JSON
      if (lottieContainer.value) {
        lottie.loadAnimation({
          container: lottieContainer.value, // Usar la referencia correctamente
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: require('@/assets/corazon.json'),
          scale: 0.2 // Aquí puedes ajustar el tamaño de la animación (0.5 para 50% del tamaño original)

        });
        
      }
      window.addEventListener('scroll', handleScroll);
      updateCountdown();
      countdownInterval = setInterval(updateCountdown, 1000);

      const target = 17; // Número objetivo que queremos alcanzar
      const interval = setInterval(() => {
        if (numero.value < target) {
          numero.value++; // Incrementa el número en 1
        } else {
          clearInterval(interval); // Detiene el intervalo cuando alcanza el objetivo
        }
      }, 100);

      const elements = [textRef.value]; // Cambia esto si tienes más elementos que observar

      const observerOptions = {
        root: null, // Usar el viewport como raíz
        rootMargin: '0px',
        threshold: [0.1] // Activar cuando el 10% del elemento sea visible
      };

      const observerCallback = (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const element = entry.target;

            if (element === textRef.value) {
              element.classList.add('animate-slideInLeft'); // Animación de izquierda a derecha
            }

            element.addEventListener('animationend', () => {
              if (element === textRef.value) {
                element.classList.remove('animate-slideInLeft');
              }
            }, { once: true });
          }
        });
      };

      const observer = new IntersectionObserver(observerCallback, observerOptions);

      // Observar cada elemento
      elements.forEach(element => {
        if (element) {
          observer.observe(element);
        }
      });
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
      clearInterval(countdownInterval);
    });


    return {
      numero, // Retorna el número para que sea accesible en el template
      fechaevento,
      textRef,
      countdownText,
      fotoPortada,
      isVisible,
      text,
      showMenu,
      toggleMenu,
      isNavbarHidden,
      scrollThreshold,
      lottieContainer
    };
  },
};

</script>


<style scoped>
/* Barra de navegación */


/* Texto animado */
.animated-text {
  flex-grow: 1;
  text-align: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 5s ease;
}

.fade-enter, .fade-leave-to /* .fade-leave-active en Vue <2.1.8 */ {
  opacity: 0;
}

.animated-text p {
  font-family: 'Dancing Script', cursive;
  font-size: 24px;
  color: #4a3f3fef;
  animation: scaleText 1s infinite alternate;
}

@keyframes scaleText {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

/* Estilo del menú desplegable */
.menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: rgba(234, 219, 219, 0.95); /* Fondo del contenedor con 70% opacidad */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.menu a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
}

.menu a:hover {
  color: #007bff;
}

/* Asegurar visibilidad del ícono de menú hamburguesa */
.menu-icon {
  cursor: pointer;
  font-size: 24px;
  color: #333;
  position: absolute; /* Cambié a posición absoluta */
  top: 10px; /* Ajusté la posición para que siempre esté visible */
  right: 20px; /* Alineado a la derecha */
  z-index: 1000; /* Para que esté por encima de otros elementos */
}




/* Contenedor de la portada */

.fotoportada-container {
  position: relative;
  width: 100%;
  min-height: calc(60vh - 60px); /* Resta la altura de la barra de navegación */
  overflow: hidden;
  margin-top: -58px; /* Ajusta según la altura de la barra de navegación */

  /* Degradado rosado en la parte inferior */
  background: linear-gradient(to bottom, rgba(208, 201, 208, 0.6), rgba(255, 255, 255, 0.8));

  background-size: cover; /* Cubre todo el contenedor */
  background-repeat: no-repeat; /* No repetir la imagen */
  background-position: top center; /* Alinea la imagen a la parte superior y centrada horizontalmente */
  z-index: 1; /* Asegúrate de que esté detrás del contenido superpuesto */
}

.fotoportada-img {
  width: 100%;
  height: 100%; /* Asegura que la imagen ocupe todo el contenedor */
  display: block;
  filter: brightness(80%); /* Oscurece solo las partes visibles de la imagen */
}
.svg-container {
  position: relative;
  width: 590px;
  height: 590px;
  
  top: -20px; /* Ajusta este valor según lo necesites */
left: -25px;
  z-index: 15;
  margin-bottom: -20px;
}

.svg-aro4 {
  position: relative; /* Cambiar a absolute para que el elemento se posicione con respecto al contenedor */
  width: 320px;
  height: 320px;
  border-radius: 50%;
  padding: 0;
  z-index: 10;
 
  left: 50%; /* Ajusta horizontalmente al 50% */
  transform: translate(-50%, -50%); /* Aplica un ajuste para centrarlo correctamente */
  display: flex;
  justify-content: center; /* Centra el contenido horizontalmente */
}

.svg-aro {
  position: relative;
  width: 300px;
  height: 300px;
  background-color: white;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.40);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.40);
  box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.40);
  padding: 0;
  z-index: 10;
  left: 50%; /* Ajusta horizontalmente al 50% */
  transform: translate(-50%, -50%); /* Aplica un ajuste para centrarlo correctamente */
  top:0px;
  display: flex;
  justify-content: center;
  align-items: center; /* Centra el contenido dentro del círculo */
}

.svg-icon {
  position: relative; /* Fija el SVG dentro del contenedor */
  width: 350px; /* Ajusta el tamaño del SVG */
  height: 350px; /* Ajusta el tamaño del SVG */
  object-fit: contain; /* Mantiene las proporciones del SVG */
  right:-30px;
  top:130px;


}
.countdown-container {
  background: linear-gradient(to top, rgba(89, 102, 126, 0.169), rgba(255, 255, 255, 0.8)); /* Degradado volteado */
  color: white;  /* Cambiar color de texto a blanco para que se vea sobre el fondo oscuro */
  padding: 15px; /* Espaciado interno para que el contenido no quede pegado a los bordes */
  border-radius: 0px; /* Opcional: Bordes redondeados */
  max-height: 250px; /* Ajusta este valor para limitar la altura */
  justify-content: space-between;  white-space: nowrap; /* Evita que el texto se parta */
 
}

.countdown {
  position: absolute; /* Coloca el contador encima del SVG */
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  white-space: nowrap; /* Evita que el texto se parta */
  overflow: hidden;

  color: black; /* Color de texto del contador */
  z-index: 2; /* Asegura que el contador esté encima del SVG */
  width: 80%; /* Ajusta el contador al tamaño del contenedor */
  top: 50%; /* Centra el contador verticalmente */
  transform: translateY(-30%); /* Ajuste preciso para centrar verticalmente */

  right:10px;

}

.countdown-item {
  padding: 0 15px; /* 15px de padding a la izquierda y derecha */
  border-right: 1px solid rgba(0, 0, 0, 0.13); /* Borde en el lado derecho */

}
/* Eliminar el borde derecho del último .countdown-item */
.countdown-item:last-child {
  border-right: none;
  margin-right: -1px; /* Elimina el margen derecho del último item */
}

.countdown-item span {
  font-size: 24px; /* Ajusta el tamaño de los números del contador */
  font-weight: bold;  white-space: nowrap; /* Evita que el texto se parta */

}

.countdown-item label {
  font-size: 12px; /* Ajusta el tamaño de las etiquetas */
  display: block;
}

  /* Animación de acercamiento */
@keyframes popupFadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8); /* Comienza más pequeño */
  }
  100% {
    opacity: 1;
    transform: scale(1); /* Termina en su tamaño original */
  }
}
.overlay {
  position: absolute; /* Cambiado a absolute para cubrir toda la imagen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centra el contenido en el medio */
  color: white;
  z-index: 2;
  padding-top:550px; /* Añade padding superior */
}


.fotoPortada {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'Daydream';
  src: url('../assets/fonts/daydream.ttf') format('truetype');  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v31/qkBIXvYC6trAT55ZBi1ueQVIjQTDeJqaE0lK.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.centered-text {
  position: absolute;
  font-size: 46px;
  font-weight: 600;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  color: rgba(57, 53, 53, 0.904);
  margin-bottom:0px;
  font-family: 'Dancing Script', cursive;
  background: linear-gradient(270deg, #393433, #d0c4c4, #7d5f59);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: animateText 3s linear infinite;
  opacity: 0; /* Para que empiece invisible */
  transform: translateY(80px);
  animation: fadeInUp 1s ease forwards; /* Controla la duración y suavidad */
  animation-delay: 0.5s; /* Retraso de 0.2 segundos para asegurar visibilidad */

}


@keyframes animateText {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: 0 0;
  }
}


.text {
  font-size: 18px;
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
  margin: 0 10px; /* Ajusta el espaciado entre la línea y el texto */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Karla'; /* Cambia la familia de fuentes según tu necesidad */
  opacity: 0; /* Para que empiece invisible */
  transform: translateY(80px);
  animation: fadeInUp 1s ease forwards; /* Controla la duración y suavidad */
  animation-delay: 0.5s; /* Retraso de 0.2 segundos para asegurar visibilidad */
}

.line {
  content: " ";
  display: block;
  width: 40px; /* Ajusta el largo de la línea */
  height: 2px; /* Grosor de la línea */
  background-color: #ebe5e5a1; /* Color de la línea */
   /* Añadir la animación aquí */
   opacity: 0; /* Para que empiece invisible */
  transform: translateY(80px);
  animation: fadeInUp 1s ease forwards; /* Controla la duración y suavidad */
  animation-delay: 0.5s; /* Retraso de 0.2 segundos para asegurar visibilidad */

}

.centered-fechatext {
  font-size: 10px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2px;
  color: rgba(237, 230, 230, 0.934); /* Color del texto */
  margin-top: -10; /* Asegúrate de que no haya margen superior */
  font-family: 'karla';
  letter-spacing: 2px;
  /* Estilos de fondo */
  padding-left: 0;
  padding-right: 0;
  background-color: rgba(83, 72, 72, 0.5); /* Fondo semitransparente */
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Sombra para profundidad */
  max-width: 80%; /* Ancho máximo para que no ocupe toda la pantalla */
}








  .animated-text p {
    font-size: 20px; /* Reduce el tamaño del texto en pantallas pequeñas */
  padding-left: 0;
  padding-right: 0;

  margin: 0;

  display: flex;

}
.animated-text {
  display: flex; /* Asegúrate de que el contenedor tenga display: flex */
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center; /* Centra el contenido verticalmente */
  text-align: center; /* Asegúrate de que el texto esté centrado */
}



.animate__fadeInUp {
  animation: fadeInUp 0.5s forwards; /* Cambia la duración como necesites */
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px); /* Comienza 20px más abajo */
    opacity: 0; /* Comienza oculto */
  }
  to {
    transform: translateY(0); /* Vuelve a su posición original */
    opacity: 1; /* Se hace visible */
  }
}

.fade-leave-active {
  opacity: 0; /* Se desvanecerá */
  transition: opacity 0.3s ease;
}




/* Animación de entrada desde abajo */
.fade-in-up {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease forwards; /* Duración y suavizado */
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px); /* Empieza desplazado 20px hacia abajo */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Llega a la posición final */
  }
}
.superposed-image {
  position: absolute; /* Mantiene la imagen posicionada absolutamente dentro del contenedor */
  top: 68%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  z-index: 5;
  
}

.textfaltan {
  position: absolute;
  top: 40%; /* Ajusta la posición vertical */
  left: 57%; /* Ajusta la posición horizontal */
  transform: translate(-50%, -50%); /* Centra el texto */
  font-size: 36px; /* Ajusta el tamaño de la fuente */
  color: #ffffff; /* Ajusta el color del texto */
  z-index: 3; /* Asegura que esté encima del SVG */
  font-family: 'Dancing Script', cursive;
  
  background: linear-gradient(270deg, #8b7d57, #000000, #777849);
  background-size: 200% 100%;
  background-position: 0 0;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: gradientAnimation 5s ease infinite; /* Animación */
}

@keyframes gradientAnimation {
  0% {
    background-position: 200% 0; /* Inicia desde la derecha */
  }
  100% {
    background-position: 0 0; /* Termina a la izquierda */
  }}
  .animate-slideInLeft {
  animation: slideInLeft 1s ease-out;
}


.lottie-animation-container {
  width: 30px;  /* Ajusta el tamaño según lo necesites */
  height: 30px; /* Ajusta el tamaño según lo necesites */
  margin: 0 auto;
  display: flex;
  justify-content: center;  /* Centra horizontalmente */
  align-items: center;      /* Centra verticalmente */
  margin-left: 190px;  /* Esto moverá el contenedor a la derecha */
  margin-top:20px;

}


.svg-icon2 {
  position: absolute;
  top: 47%; /* Ajusta para posicionar verticalmente */
  left: 57%; /* Ajusta para posicionar horizontalmente */
  transform: translate(-50%, -50%); /* Centra el elemento */
  width: 100px; /* Cambia el tamaño según lo necesites */
  height: auto; /* Mantiene la proporción */
  z-index: 2; /* Asegura que esté sobre otros elementos */
}
</style>