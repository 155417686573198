<template>
  <div>

    <!-- Contenedor Bienvenida Padres -->
    <div
      class="padres-container"
    >
    <img
        src="@/assets/lineas01.svg"
        alt="Decoración SVG"
        class="decorative-svg"
      >
   
      <div
        ref="texto0Ref"
        class="texto-container texto-novia"
      >
        <span class="novia">
<br>Mis Padres       </span>
       
      </div>
    
      <!-- Texto 1 -->
      <div
        ref="texto1Ref"
        class="texto-container text"
      >
      <span class="texto-container3">Con la bendición de mis Padres</span> <br>

       <br>Juan Manuel González Andrade<br> <br>: Olivia Martínez Villanueva  <br>
      </div>
         <!-- Texto 1 -->
   
     
      <div
        ref="texto3Ref"
        class="texto-container3"
      >
        <span class="titulo-novio"></span> 
  <!--  <i
          class="fas fa-cross icono"
          style="margin-left: 5px;"
        />  -->
       
  
      </div>
    
    </div>
  </div>
</template>
    
  <script>
  import { onMounted, ref, nextTick } from 'vue';
  import 'animate.css';

  export default {
    name: 'BienvenidaPadresPage',
    setup() {
      const texto1Ref = ref(null);
      const texto2Ref = ref(null);
      const texto3Ref = ref(null);
      const texto0Ref = ref(null);

      const observeElement = (element) => {
        if (element.value) {
          const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: [0.1],
          };
  
          const observerCallback = (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                element.value.classList.add('animate__animated', 'animate__fadeInUp');
                element.value.style.opacity = '1';
                element.value.style.transform = 'translateY(0)';
  
                element.value.addEventListener('animationend', () => {
                  element.value.classList.remove('animate__animated', 'animate__fadeInUp');
                }, { once: true });
              } else {
                element.value.style.opacity = '0';
                element.value.style.transform = 'translateY(50px)';
              }
            });
          };
  
          const observer = new IntersectionObserver(observerCallback, observerOptions);
          observer.observe(element.value);
        }
      };
  
      onMounted(async () => {
        await nextTick();
        observeElement(texto0Ref);

        observeElement(texto1Ref);
        observeElement(texto2Ref);
        observeElement(texto3Ref);
      });
  
      return { texto0Ref, texto1Ref, texto2Ref, texto3Ref };
    },
  };
  </script>
  
  <style scoped>
@font-face {
  font-family: 'FeelingPassionate';
  src: url('@/assets/fonts/passionate.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block; /* Espera a que la fuente esté cargada antes de mostrarla */
}


@font-face {
  font-family: 'Starlove';
  src: url('../assets/fonts/starlove.ttf') format('truetype'); /* Asegúrate de usar la extensión correcta */
  font-weight: normal;
  font-style: normal;
}
  /* Contenedor Bienvenida Padres */
  .padres-container {
    position: relative;
    width: 100%;
    height: 185hv;
    text-align: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 15px;

    transform: translateY(50px);

  }

  .decorative-svg {
  display: block; /* Elimina el espacio en línea generado por el SVG */
  margin: 0; /* Sin margen */
  padding: 0; /* Sin relleno */
  line-height: 0; /* Evita espacio causado por la altura de línea */
  width: 100%; /* Ajusta el ancho según sea necesario */
  height: auto; /* Mantén las proporciones */
}
  /* Estilo para los textos */
  .texto-container {
    margin: 10px 0;
    margin-bottom: 90px;
    opacity: 0;
    font-size: 20px;
    font-family: 'Dancing Script', sans-serif;
  font-weight: 350;    color: #333;
    border-radius: 10px;
    z-index: 2;
    width: 90%;
    white-space: nowrap; /* Evita que el texto se divida en varias líneas */
    transform: translateY(50px);
  }

  .novia {
    
    font-size: 30px;
    font-family: 'FeelingPassionate';
    font-weight: 550;        color: #090d3dd4;


}
  
  .texto-container.animate__animated {
    transform: translateY(0);
    opacity: 1;
  }
  



/* Estilo para la palabra Novia */
.titulo-novia {
  font-size: 14px;

  color: #454444df; /* Cambia el color según lo desees */
  font-family: 'Roboto';

  
}

/* Estilo para la palabra Novio */
.titulo-novio {
  font-size: 14px;
  color: #454444df; /* Cambia el color según lo desees */
  font-family: 'Roboto';

}
.text{
  margin-top: -20px;
  font-size: 16px;
  letter-spacing: 0.5px; /* Ajusta este valor según el espaciado que desees */
  color: #9c916b !important; 

  font-family: "Oswald", Sans-serif;
  font-weight: 350;    

}

/* Estilo para los nombres de los novios */
.nombre-novia{
  font-family: "Oswald", Sans-serif;
  font-weight: 200;
  font-weight: 350;    color: #333333d4;
  font-size: 12px;
}

.nombre-novio {
  font-family: "Oswald", Sans-serif;
  font-weight: 200;
  font-weight: 350;    color: #333333d4;
  font-size: 12px;


}

.starlove {
  margin-top:-30px;
  font-family: 'starlove', cursive; /* Cambia 'cursive' por la fuente por defecto si deseas */
  font-size: 44px; /* Ajusta el tamaño según lo necesites */
  color: #93806d; /* Ajusta el color según tu preferencia */
  top:-20px;
}
/*cruz*/
.icono {
  color: #726c55 !important; /* Color de la cruz */
  font-size: 8px; /* Ajusta este valor según el tamaño deseado */
  vertical-align: middle; /* Alinea el ícono verticalmente con el texto */
  margin-left: 5px; /* Espacio entre el texto y el ícono */
}
/* Estilos para el contenedor de texto 2 */
.texto-container2 {
  padding-bottom: 40px;

  margin: 10px 0;
  opacity: 0;
  font-size: 20px;
  font-family: 'Dancing Script', sans-serif;
  font-weight: 350;
  color: #333;
  border-radius: 10px;
  z-index: 2;
  width: 90%;
  margin-top: 0;
  margin-bottom: 60px;
  transform: translateY(50px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Sombra sutil */
  background: #fff; /* Fondo blanco para la tarjeta */
  transition: all 0.3s ease; /* Suavizar la transición */
}

/* Estilos para el contenedor de texto 3 */
.texto-container3 {

  opacity: 0;
  font-size: 26px;
  font-family: 'Dancing Script', sans-serif;
  font-weight: 350;
  color: #333;

  transform: translateY(50px);
 
  transition: all 0.3s ease; /* Suavizar la transición */
}
/* Animación de entrada para los contenedores */
@keyframes floatIn {
  to {
    opacity: 1;
    transform: translateY(0); /* Movimiento desde abajo hacia arriba */
  }
}

/* Aplicando la animación cuando la tarjeta entra en la vista */
.texto-container2,
.texto-container3 {
  animation: floatIn 1s ease-out forwards;
}

/* Efecto al pasar el ratón */
.texto-container2:hover,
.texto-container3:hover {
  transform: translateY(-5px); /* Eleva un poco la tarjeta */
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada */
}

/* Agregar animación a la entrada desde el fondo */
.texto-container2,
.texto-container3 {
  animation: floatIn 1s ease-out forwards;
}

 .nombre-novioo{
  font-size: 22px;
    font-family: 'Dancing Script', sans-serif;
  font-weight: 350;    color: #333;

 }

  </style>
  
