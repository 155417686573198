<template>
  <div>
    <!-- Contenedor Bienvenida -->
    <div class="bienvenida-container">
      <div class="overlay">
        <div ref="tex1Ref" class="tex1">
          <br> Código de Vestimenta
        </div>
      </div>

      <!-- Segundo Overlay debajo del primero -->
      <div ref="bienvenidaRef" class="overlay2"></div>

      <div ref="text2Ref" class="text2">
        <br> Formal
      </div>

      <div ref="text3Ref" class="text3">
        <div class="c3">
          <br> Reservamos el color Azul Rey para la Quinceañera. 
          <i class="fas fa-heart"></i>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { onMounted, ref, nextTick } from 'vue';


export default {
  name: 'BienvenidaPage',
  setup() {
    const tex1Ref = ref(null);
    const bienvenidaRef = ref(null);
    const rightIconRef1 = ref(null);
    const rightIconRef2 = ref(null);
    const collageRef = ref(null);
    const text2Ref = ref(null);
    const text3Ref = ref(null);

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    }, {
      threshold: 0.1, // Activar cuando el 10% del elemento sea visible
    });

    onMounted(() => {
      nextTick(() => {
        // Asegurarse de observar todos los elementos que deben animarse
        if (tex1Ref.value) observer.observe(tex1Ref.value);  // Asegurar que tex1 se observe
        if (rightIconRef1.value) observer.observe(rightIconRef1.value);
        if (rightIconRef2.value) observer.observe(rightIconRef2.value);
        if (collageRef.value) observer.observe(collageRef.value);
        if (bienvenidaRef.value) observer.observe(bienvenidaRef.value);  // También observa el primer overlay
        if (text2Ref.value) observer.observe(text2Ref.value); // Observar texto2
        if (text3Ref.value) observer.observe(text3Ref.value); // Observar texto3
      });
    });

    return { 
      tex1Ref, 
      bienvenidaRef, 
    
      rightIconRef1, 
      rightIconRef2, 
      collageRef, 
      text2Ref, 
      text3Ref 
    };
  }
};
</script>

<style scoped>
/* Contenedor Bienvenida */
.bienvenida-container {
  background: linear-gradient(to bottom, rgba(208, 201, 208, 0.6), rgba(255, 255, 255, 0.8));

            background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 20px;
  margin: 0;
  z-index: 1;
  font-family: 'Dancing Script', cursive;
  font-size: 26px;
  font-weight: 300;
  text-align: center;
  display: flex;
  align-items: center;

  flex-direction: column;
  background-size: auto; /* Mantiene el tamaño real de la imagen */
  background-position: center; /* Centra la imagen en el contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  align-items: center;
  gap: 20px;
  box-sizing: border-box; /* Asegura que el padding no agregue más ancho */
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
}

.c2 {
  display: flex;
  flex-direction: column; /* Apila los elementos verticalmente */
  position: relative;
  width: 90%; /* Reduce el ancho para que no ocupe toda la pantalla */
  max-width: 600px; /* Limita el tamaño en pantallas grandes */
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  align-items: center; /* Centra los elementos hijos en el eje horizontal */
  text-align: center;
  background: rgba(255, 255, 255, 0.8); /* Fondo semitransparente */
  border-radius: 12px; /* Bordes redondeados */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); /* Sombra para el efecto flotante */
}

.c3 {
  display: flex;
  flex-direction: column; /* Apila los elementos verticalmente */
  position: relative;
  width: 90%; /* Reduce el ancho para que no ocupe toda la pantalla */
  max-width: 600px; /* Limita el tamaño en pantallas grandes */
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  align-items: center; /* Centra los elementos hijos en el eje horizontal */
  text-align: center;
  background: rgba(255, 255, 255, 0.8); /* Fondo semitransparente */
  border-radius: 12px; /* Bordes redondeados */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); /* Sombra para el efecto flotante */
}

/* Contenedor Overlay */
.overlay, .overlay2 {
  border-radius: 15px;
  width: 90%; /* Se ajusta al 90% del ancho de la pantalla */
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column; /* Cambia a columna para que el contenido se apile verticalmente */
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Dancing Script', cursive;
  font-size: 32px;
  color: #917087d4;
  flex-direction: row; /* Alinea los iconos en una fila horizontal */
  margin-top:-50px;
}


.overlay2{
  margin-bottom: -10px;
}
/* Elementos dentro de overlay */
.tex1 {
  margin-top:30px;
  
  font-size: 28px ! important;
  font-family: 'Dancing Script', sans-serif;
  font-weight: 350;    
  color: #333;
  font-size: 24px;
  text-align: center; /* Alinea el texto horizontalmente al centro */
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 1s, transform 1s;
}

/* Animación de tex1 cuando se haga visible */
.visible.tex1 {
  opacity: 1;
  transform: translateX(0);
}

.text2 {
  font-size: 30px;
  font-weight: 400;
  margin-top:20px;
  font-family: 'FeelingPassionate';
  color: #1a1818cd;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 1s, transform 1s;
}

.text3 {
  font-size: 22px;
  font-weight: 400;
  font-family: 'Dancing Script', sans-serif;
  color: #1a1818cd;
  margin-left: 20px; /* Margen izquierdo */
  margin-right: 20px; /* Margen derecho */
  padding-left: 10px; /* Padding izquierdo */
  padding-right: 10px; /* Padding derecho */
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 1s, transform 1s;
}

/* Estilo del icono */
.icon {
  margin: 30px 0; /* Añade margen vertical a los iconos */
  opacity: 0;
  margin-top:40px;
  transform: scale(0);
  margin-bottom: 10px;
  justify-content: center; /* Centra el icono horizontalmente */
  align-items: center; /* Centra el icono verticalmente */
  transition: opacity 1s, transform 1s;
}

.icon-image {
  width: 50px;
  height: auto;
}

/* Animación de pulso */
@keyframes pulso {
  0%, 100% {
    transform: scale(1); /* Tamaño original */
  }
  50% {
    transform: scale(1.1); /* Aumenta el tamaño en un 10% */
  }
}

/* Aplicar animación de pulso */
.pulso {
  animation: pulso 1.5s infinite; /* Duración y que se repita infinitamente */
}

/* Centrar la imagen separador */
.separador-img {
  width: 100%;
  max-width: 50%; /* Cambia esto para hacerla más pequeña */
  height: auto;
  display: block;
  margin-top: 20px;
}
/* Centrar la imagen separador */
.separador-img2 {
  width: 100%;
  max-width: 80%; /* Controla el tamaño máximo */
  height: auto;
  display: block;
  margin: 20px auto 0 auto; /* Esto centrará la imagen automáticamente */
}

/* Contenedor del collage */
.collage {
  display: flex; /* Alinea las imágenes en fila */
  justify-content: space-between; /* Espacio entre las imágenes */
  gap: 10px; /* Agrega un pequeño espacio entre las imágenes */
  width: 80%; /* Ajusta el ancho al 90% del contenedor */
  max-width: 1000px; /* Limita el ancho máximo */
  margin: 10px auto; /* Centra el contenedor con márgenes automáticos en los lados */
  opacity: 0; /* Comienza invisible */
  transform: scale(0.8); /* Comienza con un tamaño más pequeño */
  transition: opacity 1s, transform 1s; /* Transiciones para hacer el cambio suave */
}

/* Estilo para cada imagen dentro del collage */
.collage .image-item {
  flex: 1; /* Hace que cada imagen ocupe el mismo espacio */
  max-width: 45%; /* Limita el tamaño máximo de las imágenes */
}

/* Cuando el collage se vuelve visible, aplica la animación */
.collage.visible {
  opacity: 1;
  transform: scale(1); /* Aumenta el tamaño a su tamaño original */
  transition: opacity 1s, transform 1s ease-out; /* Transición suave al tamaño original */
}


/* Imagen dentro del collage */
.collage-image {
  width: 100%;
  height: 100%;
  height: auto;

}

/* Cuando los elementos se hacen visibles */
.visible {
  opacity: 1;
  transform: translateX(0);
}

.c2 {
  font-family: 'Dancing Script', cursive;
  font-size: 23px;
  color: #342d29d4;
  display: flex;
  flex-direction: column; /* Apila los elementos verticalmente */
  align-items: center; /* Centra los elementos hijos horizontalmente */
  justify-content: center; /* Opcional si quieres centrar también en altura */
  position: relative;
  width: 100%; /* Ocupa todo el ancho disponible */
  width: 80%; /* Controla el ancho máximo */
  margin: 0 auto; /* Centra el contenedor en la página */
  text-align: center;
}.pinterest-button {
  font-family: 'Karla', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #6b6d48;
  text-decoration: underline; /* Subrayado siempre */
  padding: 5px 10px;
  border-radius: 5px;
  transition: color 0.3s ease-in-out;
  display: inline-block;
}

.pinterest-button:hover {
  color: #8a8d64;
  text-decoration: underline; /* Asegurar que siga subrayado */
}


</style>
