<template>
  <div class="app">
    <!-- Modal de confirmación -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <img src="@/assets/corona.png" alt="Icono" class="modal-icon" />
          <h2 class="modal-title">¿Te gustaria reproducir la música de la invitación?</h2>
        </div>
        <div class="modal-buttons">
          <button @click="acceptMusic" class="modal-button">
            <i class="fas fa-music"></i> Sí, quiero escuchar
          </button>
          <button @click="declineMusic" class="modal-button decline">
            <i class="fas fa-times"></i> No, gracias
          </button>
        </div>
      </div>
    </div>

    <!-- Reproductor de música -->
    <div class="music-player" v-show="!showModal">
      <audio
        ref="audio"
        :src="songSource"
        preload="auto"
        @timeupdate="updateProgress"
        @ended="resetPlayer"
      ></audio>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      songSource: require('@/assets/musicafondo.mp3'), // Ruta del archivo de música
      isPlaying: false,
      progress: 0,
      showModal: true, // Mostrar el modal al cargar
    };
  },
  methods: {
    acceptMusic() {
      this.showModal = false;
      this.playMusic(); // Reproduce la música
    },
    declineMusic() {
      this.showModal = false; // Solo cierra el modal
    },
    playMusic() {
      this.$refs.audio.play();
      this.isPlaying = true;
    },
    pauseMusic() {
      this.$refs.audio.pause();
      this.isPlaying = false;
    },
    updateProgress() {
      const audio = this.$refs.audio;
      this.progress = (audio.currentTime / audio.duration) * 100;
    },
    resetPlayer() {
      this.isPlaying = false;
      this.progress = 0;
    },
  },
};
</script>

<style scoped>
/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  background-image: linear-gradient(rgba(23, 22, 22, 0.735), rgba(17, 17, 17, 0.479)), url(@/assets/donde.jpg); /* Gradiente y fondo de imagen */

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-image: linear-gradient(rgba(23, 22, 22, 0.735), rgba(17, 17, 17, 0.479)), url(@/assets/donde.jpg); /* Gradiente y fondo de imagen */
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 90%;
}

.modal-header {
  margin-bottom: 20px;
}

.modal-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.modal-title {
  font-family: 'Pacifico', cursive;
  font-size: 1.5rem;
  color: #ffff;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.modal-button {
  background: #3e3e4b;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Jost', sans-serif;
  transition: background 0.3s ease;
}

.modal-button i {
  font-size: 18px;
}

.modal-button:hover {
  background: #2b2a39;
}

.modal-button.decline {
  background: #c4c4c4;
}

.modal-button.decline:hover {
  background: #a3a3a3;
}

/* Reproductor de música */
.music-player {
  display: none; /* Oculta el reproductor hasta que el modal desaparezca */
}
</style>
