<template>
  <div>
    <!-- Contenedor Bienvenida -->
    <div
      ref="bienvenidaRef"
      class="bienvenida-container"
    >
      <p class="bienvenida-text">
        {{ mensaje }}
      </p>
      <p
        v-if="mostrarMensajeFinal"
        class="textmia"
      >
        {{ mensajeFinal }}
      </p>
    <!--  <img
        :src="separadorImg"
        alt="Separador"
        class="separador-img"
      >-->
    </div>
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';
import 'animate.css';
import separadorImg from '@/assets/separador2.png';

export default {
  name: 'BienvenidaPage',
  setup() {
    const bienvenidaRef = ref(null);
    const mensaje = ref(""); // Primer mensaje a mostrar
    const mensajePequeño = ref(""); // Texto pequeño adicional
    const mensajeFinal = ref(""); // Segundo mensaje a mostrar
    const text = "Hay momentos inolvidables que se quedan en el corazón para siempre, mis XV años será uno de esos y me encantaría compartirlo contigo.";
    const textPequeño = ""; // Texto pequeño adicional
    const textFinal = "Ingrid Olivia"; // Texto final a mostrar
    let hasStartedWriting = false;
    const animationDone = ref(false);
    
    const mostrarMensajeFinal = ref(false);
    const mostrarMensajePequeño = ref(false);

    const escribirTexto = (texto, index, callback) => {
      if (index < texto.length) {
        mensaje.value += texto.charAt(index);
        setTimeout(() => escribirTexto(texto, index + 1, callback), 50);
      } else if (callback) {
        callback();
      }
    };

    const escribirTextoPequeño = (texto, index, callback) => {
      if (index < texto.length) {
        mensajePequeño.value += texto.charAt(index);
        setTimeout(() => escribirTextoPequeño(texto, index + 1, callback), 50);
      } else if (callback) {
        callback();
      }
    };

    const escribirTextoFinal = (texto, index) => {
      if (index < texto.length) {
        mensajeFinal.value += texto.charAt(index);
        setTimeout(() => escribirTextoFinal(texto, index + 1), 50);
      }
    };

    onMounted(async () => {
      await nextTick();

      if (bienvenidaRef.value) {
        const element = bienvenidaRef.value;

        const observerOptions = {
          root: null,
          rootMargin: '0px',
          threshold: [0.1]
        };

        const observerCallback = (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              if (!animationDone.value) {
                // Solo animar el texto y no el contenedor
                if (!hasStartedWriting) {
                  setTimeout(() => {
                    escribirTexto(text, 0, () => {
                      mostrarMensajePequeño.value = true;
                      escribirTextoPequeño(textPequeño, 0, () => {
                        mostrarMensajeFinal.value = true;
                        escribirTextoFinal(textFinal, 0);
                      });
                    });
                  }, 500);
                  hasStartedWriting = true;
                }

                animationDone.value = true;
              }
            } else {
              animationDone.value = false;
            }
          });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);
        observer.observe(element);
      }
    });

    return { bienvenidaRef, mensaje, mensajePequeño, mensajeFinal, mostrarMensajeFinal, mostrarMensajePequeño, separadorImg };
  }
};
</script>

<style scoped>
/* Contenedor Bienvenida */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v31/qkBIXvYC6trAT55ZBi1ueQVIjQTDeJqaE0lK.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.bienvenida-container {
  position: relative;
  width: 100%;
  padding-top: 30px;
  margin: 0;
  text-align: center;
  background: linear-gradient(to bottom, rgba(89, 102, 126, 0.169), rgba(255, 255, 255, 0.8));
  background-size: cover;
  background-position: center;
  opacity: 1; /* Asegura que el contenedor esté visible */
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Estilos para el texto de bienvenida */
.bienvenida-text {
  font-size: 19px; /* Ajustar tamaño del texto */
  font-weight: 400;
  font-family: 'Josefin Sans', sans-serif;
  color: rgba(17, 14, 55, 0.92);
  margin: 0;
  font-weight: 400;
  margin-bottom: 40px;
  margin: 0 20px 40px 20px; /* Márgenes: arriba 0, derecha 20px, abajo 40px, izquierda 20px */
  white-space: pre-line;}

@font-face {
  font-family: 'FeelingPassionate';
  src: url('@/assets/fonts/passionate.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.textmia {
  font-family: 'Eyesome Script', cursive;
  font-size: 44px;
  opacity: 1;
  font-weight: 400;
  color: #140939d4;
  line-height: 1.9; /* Ajusta la separación entre líneas */
  white-space: normal; /* Permite que el texto se ajuste a múltiples líneas */
  word-break: break-word; /* Asegura que el texto largo se ajuste en caso de ser necesario */
  margin-top: 10px;
}

.separador-img {
  width: 30%;
  max-width: 50hv;
  height: auto;
  display: block;
  margin: 0 auto;
}
</style>
