<template>
    <div>
      <!-- Contenedor Mensaje de Bienvenida -->
      <div
        ref="bienvenidaRef"
        class="bienvenida-container"
      >
        <div class="mensaje-bienvenida2">
          <img
            src="@/assets/hashtag.gif"
            alt="Bienvenida"
            class="gif-pequeno"
          >
        </div>
        <div class="mensaje-bienvenida">
      Comparte tus mejores momentos con el HashTag <strong>en Instagram</strong>
        </div> 
  
        <!-- Nuevo texto adicional -->
        <div class="nuevo-texto">
          <br> #XVZOE <br>
        </div>
  
        <!-- Resto del contenido... -->
      </div>
    </div>
  </template>
  
  <script>
  import { onMounted, ref, nextTick } from 'vue';
  import 'animate.css'; // Asegúrate de importar animate.css
  import lottie from 'lottie-web';
  
  export default {
    name: 'BienvenidaPage',
    setup() {
      const bienvenidaRef = ref(null);
      const animationDone = ref(false);
      const mensaje = ref(""); // Mensaje que se mostrará
      const text = "Nuestras almas ya estaban conectadas, hoy solo ponemos nombre a esa unión.";
      let hasStartedWriting = false; // Para controlar si la escritura ya comenzó
      const lottieContainer = ref(null);
  
  
      const escribirTexto = (texto, index) => {
        if (index < texto.length) {
          mensaje.value += texto.charAt(index);
          setTimeout(() => escribirTexto(texto, index + 1), 50); // Cambia el número para ajustar la velocidad
        }
      };
  
      onMounted(async () => {
         // Cargar la animación Lottie desde el JSON
         if (lottieContainer.value) {
          lottie.loadAnimation({
            container: lottieContainer.value, // Usar la referencia correctamente
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('@/assets/corazon.json'),
            scale: 0.2 // Aquí puedes ajustar el tamaño de la animación (0.5 para 50% del tamaño original)
  
          });}
        await nextTick();
  
  
        if (bienvenidaRef.value) {
          const element = bienvenidaRef.value;
  
          const observerOptions = {
            root: null,
            rootMargin: '0px 0px -20% 0px', // Permite que el elemento comience a animarse un poco después de ser visible
  
            threshold: [0.3]
          };
  
          const observerCallback = (entries) => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                if (!animationDone.value) {
                  element.classList.add('animate__animated', 'animate__fadeInUp');
                  element.addEventListener('animationend', () => {
                    element.classList.remove('animate__animated', 'animate__fadeInUp');
                    element.style.opacity = '1';
                  }, { once: true });
  
                  // Iniciar la escritura solo si no ha comenzado antes
                  if (!hasStartedWriting) {
                    escribirTexto(text, 0);
                    hasStartedWriting = true; // Marcar que ya se inició la escritura
                  }
  
                  animationDone.value = true;
                }
              } else {
                element.style.opacity = '0';
                animationDone.value = false;
              }
            });
          };
  
          const observer = new IntersectionObserver(observerCallback, observerOptions);
          observer.observe(element);
        }
      });
  
      return { bienvenidaRef, mensaje, lottieContainer };
    }
  };
  </script>
  
  <style scoped>
  /* Contenedor Mensaje de Bienvenida */
  .bienvenida-container {
    position: relative;
    width: 100%;
    padding-top: 30px;
    margin: 20;
    text-align: center;
    z-index: 1;
    padding-bottom: 50px; /* Aumenta este valor según lo necesario */
    background: linear-gradient(135deg, rgba(201, 196, 196, 0.95), rgba(67, 72, 67, 0.495)), 
              url('../assets/donde.jpg');
  background-size: cover;
  background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.5s;
  }
  
  
  
  .bienvenida-container:hover {
    transform: translateY(-5px);
  }
  
  .mensaje-bienvenida {
    font-size: 18px;
    font-weight: 400;
    font-family: 'Josefin Sans', sans-serif;
    color: white;
    transition: color 0.3s;
    padding: 10px 20px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Sombra para mejorar visibilidad */
  }
  
  /* Estilos para el nuevo texto adicional */
  .nuevo-texto {
    font-size: 20px;
    opacity: 1; /* Asegura que el texto esté visible */
    color:  #f8f7f2d4;
    margin-top: -30px;
    margin-bottom: 50px; /* Espacio entre el nuevo texto y el ícono */
    text-align: center; /* Centrar el texto */
    transition: color 0.3s ease-in-out;
    margin-bottom: 0px;
  }
  
  .nuevo-texto:hover {
    color:  #917087d4; /* Cambia a un tono más suave al pasar el ratón */
  }
  
  .mensaje-bienvenida:hover {
    color: rgba(122, 101, 97, 0.8);
  }
  
  /* Contenedor del ícono */
  .icono-container {
    margin: 5px 0;
  }
  
  /* Estilos para el ícono */
  .icono {
    font-size: 32px;
    color:  #917087d4;
    animation: pulsar 1.5s infinite;
  }
  
  /* Animación para el ícono */
  @keyframes pulsar {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Estilos para el texto adicional */
  .texto-adicional {
    font-size: 24px;
    font-weight: 300;
    font-family: 'Caveat', cursive;
    margin-top: 20px;
  }
  
  /* Estilos para el contenedor de mensajes */
  .mensaje-container {
    margin-top: 10px;
    padding: 20px;
    border: 1px solid rgba(101, 93, 89, 0.7);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
  
  .mensaje-container:hover {
    transform: scale(1.02);
  }
  
  .mensaje-container h2 {
    margin-bottom: 10px;
  }
  
  .mensaje-container textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid rgba(52, 46, 44, 0.5);
    border-radius: 5px;
  }
  
  /* Estilos para el campo de nombre */
  .nombre-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid rgba(52, 46, 44, 0.5);
    border-radius: 5px;
  }
  
  /* Estilos para el botón */
  .mensaje-container button {
    padding: 10px 20px;
    background-color: rgba(114, 104, 97, 0.8);
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .mensaje-container button:hover {
    background-color: rgb(101, 87, 87);
    transform: scale(1.05);
  }
  
  /* Estilos para los mensajes enviados como tarjetas */
  .mensajes-enviados {
    margin-top: 20px;
    text-align: left;
  }
  
  .mensajes-enviados h3 {
    margin-bottom: 10px;
  }
  
  /* Estilo para cada tarjeta de mensaje */
  .mensaje-tarjeta {
    padding: 10px;
    margin: 5px 0;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .mensaje-tarjeta:hover {
    transform: translateY(-5px) rotateY(5deg);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  .gif-pequeno {
    width: 100px; /* Ajusta el tamaño según tus necesidades */
    height: auto; /* Mantiene la proporción del GIF */
  }
  </style>
  
  